<!--
 * @Description  : 
 * @Author       : BigBigger
 * @Date         : 2022-03-29 14:26:46
 * @LastEditTime : 2022-05-16 10:07:49
 * @LastEditors  : BigBigger
-->
<template>
	<van-dialog title="修改个人信息" v-model="visible" showCancelButton :beforeClose="submit">
		<van-form ref="form">
			<van-field
				v-model="form.realName"
				name="realName"
				label="真实姓名"
				placeholder="真实姓名"
				:rules="[{ required: true, message: '请填写真实姓名' }]"
			/>
			<van-field
				v-model="form.phone"
				name="phone"
				label="电话"
				placeholder="电话"
				:rules="[{ required: true, message: '请输入手机号码' }, {pattern: /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/,
            message: '请填写正确的手机号码',}]"
			/>
			<van-field
				v-model="form.totalScore"
				name="totalScore"
				label="笔试总分"
				placeholder="笔试总分"
				:rules="[{ required: true, message: '请填写笔试总分' }, {pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,3})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: '请填写正确的总分',}]"
			/>
			<van-field
				v-model="form.ranking"
				name="ranking"
				label="笔试排名"
				placeholder="笔试排名"
				:rules="[{ required: true, message: '请填写笔试排名' },{
              pattern: /^[0-9][0-9]?$/,
              message: '请输入正确排名',
            }]"
			/>
		</van-form>
	</van-dialog>
</template>

<script>
import { updatePersonalInfo } from '@/api/api';

export default {
	data() {
		return {
			visible: false,
			form: {},
		};
	},
	methods: {
		show(value) {
			this.form = value;
			this.visible = true;
		},
		submit(action, done) {
      if (action === 'confirm') {
        this.$refs.form.validate().then(() => {
          updatePersonalInfo(this.form)
            .then((res) => {
              if (+res.returnCode !== 10001) {
                done(false);
                return this.$toast('修改失败');
              }
              this.$toast('修改成功');
              done();
              this.visible = false;
              this.form = {};
              this.$emit('reload');
            })
            .catch((err) => {
              done(false);
              this.$toast('修改失败');
            });
        }).catch(() => {
          done(false)
        });
      } else {
        done();
      }
		},
	},
};
</script>
